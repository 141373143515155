import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const MaidTrainingPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>Maid Training</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.cleaninglady.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
          <div>We offer the following training for Maids</div>
          <div>• First Aid</div>
          <div>• Child Care</div>
          <div>• Washing & Drying</div>
          <div>• Personal Hygeine</div>
          <div>Please enquire about when our next classes will be.</div>
        </div>
      </div>
    </Layout>
  )
}
)

export default MaidTrainingPage

export const pageQuery = graphql`
  query {
    cleaninglady: file(relativePath: {eq: "cleaninglady.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`